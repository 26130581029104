import React, { useCallback, useState } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { EyeCrossedIcon, EyeIcon } from '~assets/icons/shared';
import { type SvgIcon } from '~types/layout';
import { isDefined } from '~utilities/types';

import { Button } from '../button';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  inputClassName?: string;
  iconStart?: SvgIcon;
  iconStartClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, inputClassName, iconStartClassName, iconStart: IconStart, children, type, ...props }, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePassword = useCallback(() => {
      setIsPasswordVisible((prev) => !prev);
    }, []);

    return (
      <div className={twMerge('flex w-full border border-pink bg-transparent p-4 lg:px-[30px] lg:py-6', className)}>
        {isDefined(IconStart) && <IconStart className={twMerge('mr-2 h-auto w-4 shrink-0', iconStartClassName)} />}
        <input
          type={type === 'password' && isPasswordVisible ? 'text' : type}
          className={twMerge(
            'flex w-full bg-transparent p-0 text-base text-white shadow-none transition-colors placeholder:text-white/40 focus-visible:outline-none disabled:cursor-not-allowed',
            inputClassName
          )}
          ref={ref}
          {...props}
        />
        {children}
        {type === 'password' && (
          <Button
            type="button"
            variant="wrapper"
            size="wrapper"
            onClick={togglePassword}
            className={cn('ml-4', { 'text-gray-blue': !isPasswordVisible, 'text-blue': isPasswordVisible })}
          >
            {isPasswordVisible ? <EyeIcon className="h-auto w-4" /> : <EyeCrossedIcon className="h-auto w-4" />}
          </Button>
        )}
      </div>
    );
  }
);
Input.displayName = 'Input';

export { Input };
