import React, { useCallback, useEffect, useState } from 'react';

import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';

import { type ReferralListResponse, UsersService, type ReferralResponse } from '~api/users';
import { ChevronRightIcon } from '~assets/icons/chevrons';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger, Spinner } from '~components/ui';
import { isDefined } from '~utilities/types';

export interface ReferralItemProps {
  referral: ReferralResponse;
}

export const ReferralItem: React.FC<ReferralItemProps> = ({ referral }) => {
  const { t } = useTranslation();
  const [accordionOpenValue, setAccordionOpenValue] = useState<string>();
  const [referrals, setReferrals] = useState<ReferralListResponse | null>(null);

  const getReferrals = useCallback(async () => {
    try {
      const referralsResponse = await UsersService.getReferrals(1, referral.id);
      setReferrals(referralsResponse);
    } catch (error) {
      console.error(error);
    }
  }, [referral]);

  useEffect(() => {
    if (isDefined(accordionOpenValue) && isNil(referrals)) {
      void getReferrals();
    }
  }, [accordionOpenValue, referrals, getReferrals]);

  console.log(referrals);

  return (
    <Accordion
      type="single"
      collapsible
      value={accordionOpenValue}
      onValueChange={(newValue) => {
        setAccordionOpenValue(newValue);
      }}
    >
      <AccordionItem value={referral.id}>
        <AccordionTrigger className="justify-start p-0 text-pink" showChevron={false}>
          <ChevronRightIcon className="mr-2 size-4 shrink-0 transition-transform duration-200 group-data-[state=open]:rotate-90" />
          {isDefined(referral.username) && referral.username.length > 0 ? referral.username : referral.telegram_id}
        </AccordionTrigger>
        <AccordionContent className="flex flex-col space-y-2 pb-0 pl-2 pt-2">
          {isNil(referrals) && <Spinner />}
          {isDefined(referrals) &&
            referrals.count > 0 &&
            referrals.results.map((referral) => <ReferralItem key={referral.id} referral={referral} />)}

          {isDefined(referrals) && referrals.count === 0 && <span>{t('team.noReferrals')}</span>}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};
