import React from 'react';

import StakingStore from './staking.store';
import UserStore from './user.store';

interface StoreProviderProps extends React.PropsWithChildren {
  store: RootStore;
}

export class RootStore {
  userStore: UserStore;

  stakingStore: StakingStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.stakingStore = new StakingStore(this);
  }
}

// @ts-expect-error: Always initialized after
export const StoreContext = React.createContext<RootStore>();

export const StoreProvider: React.FC<StoreProviderProps> = ({ store, children }) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export const useStore = (): RootStore => React.useContext(StoreContext);
