import axios from 'axios';

import { isDefined } from '~utilities/types';

import {
  type TokenBonusesResponse,
  type PurchaseTokensRequest,
  type ReferralListResponse,
  type UserResponse,
  type DepthsCountResponse,
  type LevelResponse,
  type TokenPriceResponse,
  type WithdrwaRequest,
  type TokenInfoResponse,
  type LevelPermissionsResponse,
} from './users.dto';

export const UsersService = {
  getSelf: async (token?: string): Promise<UserResponse> => {
    const { data } = await axios.get(`/api/v1/website/users/self`, {
      headers: {
        Authorization: `Bearer ${token ?? localStorage.getItem('token')}`,
      },
    });
    return data;
  },

  getTokenPrice: async (token?: string): Promise<TokenPriceResponse> => {
    const { data } = await axios.get(`/api/v1/website/token_price`, {
      headers: {
        Authorization: `Bearer ${token ?? localStorage.getItem('token')}`,
      },
    });
    return data;
  },

  getTokenInfo: async (token?: string): Promise<TokenInfoResponse> => {
    const { data } = await axios.get(`/api/v1/website/token_info`, {
      headers: {
        Authorization: `Bearer ${token ?? localStorage.getItem('token')}`,
      },
    });
    return data;
  },

  withdraw: async (payload: WithdrwaRequest): Promise<void> => {
    await axios.post(`/api/v1/website/create_withdraw`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  },

  getLevel: async (token?: string): Promise<LevelResponse> => {
    const { data } = await axios.get(`/api/v1/website/structures/current_level`, {
      headers: {
        Authorization: `Bearer ${token ?? localStorage.getItem('token')}`,
      },
    });
    return data;
  },

  getLevelPermissions: async (token?: string): Promise<LevelPermissionsResponse> => {
    const { data } = await axios.get(`/api/v1/shared/structures/levels_permissions`, {
      headers: {
        Authorization: `Bearer ${token ?? localStorage.getItem('token')}`,
      },
    });
    return data;
  },

  getReferrals: async (
    depth: number,
    childId?: string,
    limit: number = 100,
    offset: number = 0
  ): Promise<ReferralListResponse> => {
    const { data } = await axios.get(
      `/api/v1/website/structures?depth=${depth}${isDefined(childId) ? `&child_id=${childId}` : ''}&limit=${limit}&offset=${offset}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    return data;
  },

  getTokenBonuses: async (token?: string): Promise<TokenBonusesResponse> => {
    const { data } = await axios.get(`/api/v1/website/structures/token_bonuses`, {
      headers: {
        Authorization: `Bearer ${token ?? localStorage.getItem('token')}`,
      },
    });

    return data;
  },

  getDepthsCount: async (): Promise<DepthsCountResponse> => {
    const { data } = await axios.get(`/api/v1/website/structures/depths_count`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    return data;
  },

  purchaseTokens: async (payload: PurchaseTokensRequest): Promise<UserResponse> => {
    const { data } = await axios.post('/api/v1/website/buy_tokens', payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return data;
  },
};
