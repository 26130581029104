import { makeAutoObservable } from 'mobx';

import { type PurchaseTokensRequest, UsersService, type UserResponse } from '~api/users';

import { type RootStore } from '.';

export default class UserStore {
  rootStore: RootStore;

  user: UserResponse | null;

  loaded: {
    user: boolean;
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'userStore' });
    this.rootStore = rootStore;

    this.user = null;
    this.loaded = {
      user: false,
    };
  }

  async getUser(token?: string): Promise<UserResponse | undefined> {
    try {
      const userResponse = await UsersService.getSelf(token);
      this.user = userResponse;
      return userResponse;
    } catch (error) {
      console.error(error);
    } finally {
      this.loaded.user = true;
    }
  }

  async purchaseTokens(payload: PurchaseTokensRequest): Promise<UserResponse> {
    const userResponse = await UsersService.purchaseTokens(payload);
    this.user = userResponse;
    return userResponse;
  }
}
