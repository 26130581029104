import axios from 'axios';

import { type UpdateStakingRequest, type CreateStakingRequest, type StakingResponse } from './staking.dto';

export const StakingService = {
  getLatest: async (token?: string): Promise<StakingResponse> => {
    const { data } = await axios.get(`/api/v1/website/latest_staking`, {
      headers: {
        Authorization: `Bearer ${token ?? localStorage.getItem('token')}`,
      },
    });
    return data;
  },

  create: async (payload: CreateStakingRequest): Promise<StakingResponse> => {
    const { data } = await axios.post(`/api/v1/website/staking`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return data;
  },

  update: async (id: string, payload: UpdateStakingRequest): Promise<StakingResponse> => {
    const { data } = await axios.post(`/api/v1/website/staking/${id}/update_amount`, payload, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return data;
  },

  remove: async (id: string): Promise<StakingResponse> => {
    const { data } = await axios.delete(`/api/v1/website/staking/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return data;
  },
};
