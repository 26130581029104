import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { LogoIcon } from '~assets/icons/shared';
import { GitbookIcon, SupportIcon, TelegramIcon, XIcon } from '~assets/icons/socials';
import { Button, Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '~components/ui';

export const Footer: React.FC = () => {
  const { t, i18n } = useTranslation();

  const handleLanguageSelect = useCallback(
    async (newLanguage: string) => {
      await i18n.changeLanguage(newLanguage);
    },
    [i18n]
  );

  return (
    <footer className="mt-auto grid grid-cols-2 gap-7 bg-black-gray px-4 py-6 lg:grid-cols-3 lg:items-start lg:px-[100px] lg:py-[80px] xl:px-[200px]">
      <a className="mr-auto flex items-center justify-center" href="https://cybase.io">
        <LogoIcon className="size-8 lg:size-20" />
      </a>

      <div className="ml-auto flex flex-col space-y-2">
        <Button className="w-[160px] max-lg:p-3 lg:w-[220px]">{t('footer.getStarted')}</Button>

        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-2 text-pink lg:space-x-3">
            <a href="https://t.me/Cyberbase_support" target="_blank" rel="noreferrer">
              <SupportIcon className="h-auto w-5 lg:w-8" />
            </a>
            <a href="https://twitter.com/cyberbasexyz" target="_blank" rel="noreferrer">
              <XIcon className="h-auto w-5 lg:w-8" />
            </a>
            <a href="https://t.me/Cyberbase_gr" target="_blank" rel="noreferrer">
              <TelegramIcon className="h-auto w-5 lg:w-8" />
            </a>
            <a href="https://cyberbase.gitbook.io" target="_blank" rel="noreferrer">
              <GitbookIcon className="h-auto w-5 lg:w-8" />
            </a>
          </div>

          <Select value={i18n.language} onValueChange={handleLanguageSelect}>
            <SelectTrigger className="w-min uppercase">
              <SelectValue>{i18n.language}</SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectItem className="uppercase" value="ru">
                Русский
              </SelectItem>
              <SelectItem className="uppercase" value="en">
                English
              </SelectItem>
              <SelectItem className="uppercase" value="es">
                Española
              </SelectItem>
              <SelectItem className="uppercase" value="fr">
                Française
              </SelectItem>
              <SelectItem className="uppercase" value="zh">
                中文
              </SelectItem>
              <SelectItem className="uppercase" value="ar">
                عربي
              </SelectItem>
            </SelectContent>
          </Select>
        </div>

        <a className="text-pink hover:underline max-lg:text-sm" href="mailto:info@cybase.io">
          info@cybase.io
        </a>
      </div>

      <nav className="grid w-min grid-cols-[1fr_1fr] gap-10 whitespace-nowrap text-sm uppercase max-lg:col-span-2 lg:col-start-2 lg:row-start-1 lg:text-base">
        <ul className="flex flex-col space-y-4">
          <li className="origin-left transition-[all_0.3s_ease_0s] hover:scale-105">
            <a href="https://cybase.io/#about">{t('footer.about')}</a>
          </li>
          <li className="origin-left transition-[all_0.3s_ease_0s] hover:scale-105">
            <a href="https://cybase.io/#cyberbase-tokens">{t('footer.cyberbaseTokens')}</a>
          </li>
          <li className="origin-left transition-[all_0.3s_ease_0s] hover:scale-105">
            <a href="https://cybase.io/#profit-distribution">{t('footer.profitDistribution')}</a>
          </li>
          <li className="origin-left transition-[all_0.3s_ease_0s] hover:scale-105">
            <a href="https://cybase.io/#cyberbase-nft">{t('footer.cyberbaseNft')}</a>
          </li>
        </ul>
        <ul className="flex flex-col space-y-4">
          <li className="origin-left transition-[all_0.3s_ease_0s] hover:scale-105">
            <a href="https://cybase.io/#tokenomics">{t('footer.tokenomics')}</a>
          </li>
          <li className="origin-left transition-[all_0.3s_ease_0s] hover:scale-105">
            <a href="https://cybase.io/#project-incubator">{t('footer.projectIncubator')}</a>
          </li>
          <li className="origin-left transition-[all_0.3s_ease_0s] hover:scale-105">
            <a href="https://cybase.io/#staking">{t('footer.staking')}</a>
          </li>
          <li className="origin-left transition-[all_0.3s_ease_0s] hover:scale-105">
            <a href="https://cybase.io/#contacts">{t('footer.contacts')}</a>
          </li>
        </ul>
      </nav>
    </footer>
  );
};
