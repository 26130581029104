import * as React from 'react';

import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { Spinner } from '../spinner';

const buttonVariants = cva(
  'shadow-none outline-none transition-all hover:opacity-70 active:opacity-90 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        text: 'inline whitespace-normal hover:underline',
        wrapper: 'inline-flex items-center justify-center bg-transparent',
        primary:
          'flex items-center justify-center border-none bg-pink-gradient font-medium uppercase text-white hover:bg-pink-gradient-button-hover hover:opacity-100',
        secondary:
          'flex items-center justify-center border border-pink font-medium uppercase text-pink hover:bg-pink hover:text-white hover:opacity-100',
      },
      size: {
        small: 'px-3 py-2 text-sm',
        default: 'p-4',
        wrapper: 'p-0',
      },
    },
    defaultVariants: {
      size: 'default',
      variant: 'primary',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  isLoading?: boolean;
  spinnerClassName?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { className, variant, size, disabled, children, spinnerClassName, isLoading = false, asChild = false, ...props },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={twMerge(buttonVariants({ variant, size }), className)}
        ref={ref}
        {...props}
        disabled={disabled ?? isLoading}
      >
        {isLoading ? (
          <>
            <Spinner className={twMerge('mr-2', spinnerClassName)} />
            {children}
          </>
        ) : (
          children
        )}
      </Comp>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
