import React from 'react';

import { Navigate, createBrowserRouter } from 'react-router-dom';

import { ProfilePage } from '~pages/profile';

import { AppRoutes } from './routes';

export const appRouter = createBrowserRouter([
  {
    path: AppRoutes.Root,
    element: <ProfilePage />,
  },
  {
    path: '*',
    element: <Navigate to={{ pathname: AppRoutes.Root, search: window.location.search }} />,
  },
]);
