import React from 'react';

import axios from 'axios';
import ReactDOM from 'react-dom/client';

import { Toaster } from '~components/ui';
import { API_BASE_URL } from '~constants/env';
import { isDefined } from '~utilities/types';

import './styles/index.scss';
import './i18n';
import { App } from './app.component';
import reportWebVitals from './reportWebVitals';
import { RootStore, StoreProvider } from './store';

axios.defaults.baseURL = API_BASE_URL;

axios.interceptors.response.use(
  (config) => config,
  async (error) => {
    if (isDefined(error) && error.response?.status === 401) {
      localStorage.removeItem('token');
      window.location.reload();
    }

    // eslint-disable-next-line @typescript-eslint/return-await
    return Promise.reject(error as Error);
  }
);

const rootElement = document.getElementById('root');
const root = isDefined(rootElement) ? ReactDOM.createRoot(rootElement) : null;

const store = new RootStore();

root?.render(
  <React.StrictMode>
    <StoreProvider store={store}>
      <App />
      <Toaster />
    </StoreProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
void reportWebVitals();
