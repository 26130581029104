import React, { useCallback, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { Select } from '@radix-ui/react-select';
import { type AxiosError } from 'axios';
import { observer } from 'mobx-react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { QRCode } from 'react-qrcode-logo';
import * as zod from 'zod';

import { Network, UsersService } from '~api/users';
import usdtImage from '~assets/icons/currency/_usdt.svg';
import { CopyIcon, HamburgerIcon, LogoIcon } from '~assets/icons/shared';
import {
  Button,
  ClipboardCopy,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Label,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '~components/ui';
import { useToast } from '~hooks/use-toast';
import { useStore } from '~store/index';
import { isDefined } from '~utilities/types';

import { type WithdrawFormValues } from './header.types';

const walletLabels = {
  wallet_address_bnb: { depositLabel: 'USDT (BEP20)', network: 'BNB', token: 'BEP20' },
  wallet_address_eth: { depositLabel: 'USDT (ERC20)', network: 'ETH', token: 'ERC20' },
  wallet_address_tron: { depositLabel: 'USDT (TRC20)', network: 'Tron', token: 'TRC20' },
};

const depositLabels = {
  [Network.BNB]: 'Network (BEP20)',
  [Network.ETH]: 'Network (ERC20)',
  [Network.TRX]: 'Network (TRC20)',
};

export const Header: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    userStore: { user, getUser },
  } = useStore();
  const { toast } = useToast();

  const [selectedWallet, setSelectedWallet] = useState<keyof typeof walletLabels>('wallet_address_bnb');
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [isRefillModalOpen, setIsRefillModalOpen] = useState(false);
  const [isWithdrwaLoading, setIsWithdrawLoading] = useState(false);

  const withdrawSchema: zod.ZodType<WithdrawFormValues> = zod.object({
    amount: zod.coerce.number().gt(0, t('buy.minAmount')),
    to_address: zod.string().min(1, t('header.withdraw.required')),
    network: zod.nativeEnum(Network),
  });

  const withdrawForm = useForm<WithdrawFormValues>({
    defaultValues: {
      amount: '',
      to_address: '',
      network: Network.BNB,
    },
    resolver: zodResolver(withdrawSchema),
  });

  const handleWithdrawModalClose = useCallback(() => {
    setIsWithdrawModalOpen(false);
    withdrawForm.reset({
      amount: '',
      to_address: '',
      network: Network.BNB,
    });
  }, [withdrawForm]);

  const handleWithdrawSubmit = useCallback(
    async (values: WithdrawFormValues) => {
      try {
        setIsWithdrawLoading(true);
        await UsersService.withdraw({ ...values, symbol: 'USDT' });
        await getUser();
        toast({ title: t('toaster.success') });
        handleWithdrawModalClose();
      } catch (error) {
        console.error(error);
        toast({
          variant: 'destructive',
          title: t('toaster.error'),
          description:
            ((error as AxiosError)?.response?.data as { detail: string })?.detail ?? t('toaster.unknownError'),
        });
      } finally {
        setIsWithdrawLoading(false);
      }
    },
    [toast, handleWithdrawModalClose, t, getUser]
  );

  const handleLogout = useCallback(() => {
    localStorage.clear();
    window.location.href = 'https://cybase.io';
  }, []);

  return (
    <>
      <header className="flex items-center justify-between p-4 lg:px-[100px] lg:py-[40px] xl:px-[200px]">
        <a className="flex items-center justify-center" href="https://cybase.io">
          <LogoIcon className="size-8 lg:size-12" />
        </a>

        <DropdownMenu>
          <DropdownMenuTrigger>
            <HamburgerIcon className="h-auto w-5 lg:hidden" />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="flex w-[280px] flex-col space-y-[10px] px-6 py-10">
            <div className="flex items-center justify-between text-sm text-pink">
              <span className="uppercase">{t('header.usdBalance')}</span>
              <span className="font-semibold">{user?.usd_balance ?? '...'}$</span>
            </div>
            <div className="flex items-center justify-between text-sm text-pink">
              <span className="uppercase">{t('header.tokenBalance')}</span>
              <span className="font-semibold">{user?.token_balance ?? '...'} CB</span>
            </div>
            <Button
              className="w-full"
              variant="secondary"
              onClick={() => {
                setIsWithdrawModalOpen(true);
              }}
            >
              {t('header.withdraw.title')}
            </Button>
            <Button
              className="w-full"
              variant="secondary"
              onClick={() => {
                setIsRefillModalOpen(true);
              }}
            >
              {t('header.refill')}
            </Button>
            <Button className="w-full" onClick={handleLogout}>
              {t('header.logout')}
            </Button>
          </DropdownMenuContent>
        </DropdownMenu>

        <div className="grid grid-cols-[auto_1fr_1fr_1fr] items-center gap-[10px] max-lg:hidden">
          <div className="flex flex-col items-end">
            <p className="font-bold text-pink">{user?.usd_balance ?? '...'}$</p>
            <p className="font-bold text-pink">{user?.token_balance ?? '...'} CB</p>
          </div>
          <Button
            className="w-[180px]"
            variant="secondary"
            onClick={() => {
              setIsWithdrawModalOpen(true);
            }}
          >
            {t('header.withdraw.title')}
          </Button>
          <Button
            className="w-[180px]"
            variant="secondary"
            onClick={() => {
              setIsRefillModalOpen(true);
            }}
          >
            {t('header.refill')}
          </Button>
          <Button className="w-[180px]" onClick={handleLogout}>
            {t('header.logout')}
          </Button>
        </div>
      </header>

      <Dialog
        open={isRefillModalOpen}
        onOpenChange={(open: boolean) => {
          setIsRefillModalOpen(open);
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('deposit.title')}</DialogTitle>
          </DialogHeader>

          <div className="flex flex-col items-center text-center">
            <div className="mt-5 flex flex-col space-y-2">
              <Label>{t('header.select')}</Label>
              <Select
                value={selectedWallet}
                onValueChange={(newValue) => {
                  setSelectedWallet(newValue as keyof typeof walletLabels);
                }}
              >
                <SelectTrigger className="rounded-none border border-pink bg-transparent p-4">
                  <SelectValue placeholder="Select event type" />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    {Object.keys(walletLabels).map((wallet) => (
                      <SelectItem value={wallet} key={wallet}>
                        {walletLabels[wallet as keyof typeof walletLabels].depositLabel}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>

            <span className="mt-5 font-medium text-pink">{t('header.scan')}</span>
            <div className="flex w-min flex-col items-center rounded-md bg-dark-gray p-2">
              {isDefined(user) && isDefined(user[selectedWallet]) && (
                <QRCode
                  fgColor="#fff"
                  bgColor="transparent"
                  size={140}
                  value={user[selectedWallet]}
                  logoImage={usdtImage}
                  logoWidth={32}
                  logoHeight={32}
                  removeQrCodeBehindLogo
                />
              )}

              <p className="mt-5 break-all text-sm">{user?.[selectedWallet]}</p>
            </div>

            <p className="mt-8">
              {t('header.send', {
                network: walletLabels[selectedWallet].network,
                token: walletLabels[selectedWallet].token,
              })}
            </p>
            <p className="mt-4 text-sm text-white/80">{t('header.warning')}</p>

            {isDefined(user) && isDefined(user[selectedWallet]) && (
              <ClipboardCopy className="mt-4 min-w-[160px]" value={user?.[selectedWallet]}>
                <CopyIcon className="mr-2 size-4" />
                {t('header.copy')}
              </ClipboardCopy>
            )}
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={isWithdrawModalOpen} onOpenChange={handleWithdrawModalClose}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('header.withdraw.title')}</DialogTitle>
          </DialogHeader>

          <Form {...withdrawForm}>
            <form
              onSubmit={withdrawForm.handleSubmit(handleWithdrawSubmit)}
              className="mt-10 flex flex-col space-y-5 lg:mt-20 lg:space-y-10"
            >
              <FormField
                control={withdrawForm.control}
                name="network"
                render={({ field: { ref: _ref, ...field } }) => (
                  <FormItem>
                    <FormLabel>{t('header.withdraw.network')}</FormLabel>
                    <FormControl>
                      <Select
                        {...field}
                        value={field.value}
                        onValueChange={(newValue) => {
                          field.onChange(newValue as Network);
                        }}
                      >
                        <SelectTrigger className="rounded-none border border-pink bg-transparent p-4">
                          <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectGroup>
                            {Object.values(Network).map((network) => (
                              <SelectItem value={network} key={network}>
                                {depositLabels[network]}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={withdrawForm.control}
                name="amount"
                render={({ field: { ref: _ref, ...field } }) => (
                  <FormItem>
                    <FormLabel>{t('header.withdraw.amount')}</FormLabel>
                    <FormControl>
                      <NumericFormat {...field} customInput={Input} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={withdrawForm.control}
                name="to_address"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t('header.withdraw.address')}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button className="mt-5 w-full" type="submit" isLoading={isWithdrwaLoading}>
                {t('header.withdraw.submit')}
              </Button>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
});
