import { makeAutoObservable } from 'mobx';

import { type CreateStakingRequest, StakingService, type StakingResponse } from '~api/staking';

import { type RootStore } from '.';

export default class StakingStore {
  rootStore: RootStore;

  staking: StakingResponse | null;

  loaded: {
    staking: boolean;
  };

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { deep: true, autoBind: true, name: 'stakingStore' });
    this.rootStore = rootStore;

    this.staking = null;

    this.loaded = {
      staking: false,
    };
  }

  async getStaking(token?: string): Promise<StakingResponse | undefined> {
    try {
      const stakingResponse = await StakingService.getLatest(token);
      this.staking = stakingResponse;
      return stakingResponse;
    } catch (error) {
      console.error(error);
    } finally {
      this.loaded = {
        ...this.loaded,
        staking: true,
      };
    }
  }

  async createStaking(payload: CreateStakingRequest): Promise<StakingResponse> {
    const stakingResponse = await StakingService.create(payload);
    this.staking = stakingResponse;
    return stakingResponse;
  }

  async updateStaking(id: string, payload: CreateStakingRequest): Promise<StakingResponse> {
    const stakingResponse = await StakingService.update(id, payload);
    this.staking = stakingResponse;
    return stakingResponse;
  }

  async deleteStaking(id: string): Promise<StakingResponse> {
    const stakingResponse = await StakingService.remove(id);
    this.staking = null;
    return stakingResponse;
  }
}
