import type React from 'react';

import { twMerge } from 'tailwind-merge';

export interface SpinnerProps {
  className?: string;
}

export const Spinner: React.FC<SpinnerProps> = ({ className }) => {
  return (
    <svg className={twMerge('size-5 animate-spin', className)} viewBox="0 0 50 50" fill="none">
      <circle
        className="animate-dash"
        cx="25"
        cy="25"
        r="20"
        strokeLinecap="round"
        stroke="currentColor"
        strokeWidth="5"
      />
    </svg>
  );
};
