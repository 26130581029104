import React, { useCallback, useEffect, useState } from 'react';

import { type AxiosError } from 'axios';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import {
  type ReferralListResponse,
  UsersService,
  type TokenBonusesResponse,
  type DepthsCountResponse,
  type LevelResponse,
  type LevelPermissionsResponse,
} from '~api/users';
import { DownloadIcon } from '~assets/icons/shared';
import { LevelsMap } from '~assets/images/levels';
import { ReferralItem } from '~components/team';
import { Button, ClipboardCopy, Spinner } from '~components/ui';
import { useStore } from '~store/index';
import { isDefined } from '~utilities/types';

export const TeamTab: React.FC = observer(() => {
  const { t, i18n } = useTranslation();

  const {
    stakingStore: { staking },
    userStore: { user },
  } = useStore();

  const [referrals, setReferrals] = useState<ReferralListResponse | null>(null);
  const [tokenBonuses, setTokenBonuses] = useState<TokenBonusesResponse | null>(null);
  const [depthsCount, setDepthsCount] = useState<DepthsCountResponse | null>(null);
  const [level, setLevel] = useState<LevelResponse | null>(null);
  const [levelPermissions, setLevelPermissions] = useState<LevelPermissionsResponse | null>(null);

  const getReferrals = useCallback(async () => {
    try {
      const referralsResponse = await UsersService.getReferrals(1);
      setReferrals(referralsResponse);
    } catch (error) {
      console.error(error);
      if ((error as AxiosError)?.response?.status === 404) {
        setReferrals({ count: 0, results: [] });
      }
    }
  }, []);

  const getLevel = useCallback(async () => {
    try {
      const levelResponse = await UsersService.getLevel();
      setLevel(levelResponse);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getLevelPermissions = useCallback(async () => {
    try {
      const levelPermissionsResponse = await UsersService.getLevelPermissions();
      setLevelPermissions(levelPermissionsResponse);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getTokenBonuses = useCallback(async () => {
    try {
      const tokenBonusesResponse = await UsersService.getTokenBonuses();
      setTokenBonuses(tokenBonusesResponse);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getDepthsCount = useCallback(async () => {
    try {
      const depthsCountResponse = await UsersService.getDepthsCount();
      setDepthsCount(depthsCountResponse);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    void getReferrals();
    void getTokenBonuses();
    void getDepthsCount();
    void getLevel();
    void getLevelPermissions();
  }, [getReferrals, getTokenBonuses, getDepthsCount, getLevel, getLevelPermissions]);

  const LevelImage = isDefined(level) ? LevelsMap[level.level as keyof typeof LevelsMap] : null;

  return (
    <div className="flex w-full flex-col items-center bg-dark-gray p-[30px] lg:p-[50px]">
      <div className="flex w-full flex-col items-center max-lg:space-y-10 lg:flex-row lg:justify-between">
        <div className="flex w-full flex-col items-center lg:flex-row lg:space-x-14">
          {isDefined(LevelImage) ? (
            <LevelImage className="h-auto w-[70px] lg:w-[100px]" />
          ) : (
            <Spinner className="size-[70px] lg:size-[140px]" />
          )}
          <h1 className="mt-5 text-center text-lg font-semibold uppercase lg:max-w-[240px] lg:text-left lg:text-5xl">
            {t('team.level')}
          </h1>
        </div>

        <div className="flex w-full max-w-[500px] flex-col space-y-3 text-sm lg:space-y-5 lg:text-md">
          <div className="flex w-full items-center justify-between">
            <span>{t('team.levelInfo.purchased')}</span>
            <span className="text-pink">{level?.by_me.to_usd ?? '—'}$</span>
          </div>
          <div className="flex w-full items-center justify-between">
            <span>{t('team.levelInfo.purchasedByReferrals')}</span>
            <span className="text-pink">{level?.by_structures.to_usd ?? '—'}$</span>
          </div>
          <div className="flex w-full items-center justify-between">
            <span>{t('team.levelInfo.required')}</span>
            <span className="text-pink">
              {isDefined(level) && isDefined(levelPermissions)
                ? levelPermissions[`level_${level.level + 1}` as keyof LevelPermissionsResponse]?.by_me?.to_usd
                : '—'}
              $
            </span>
          </div>
        </div>
      </div>
      <div className="mt-10 flex w-full items-start max-lg:flex-col max-lg:space-y-5 lg:justify-between lg:space-x-10">
        <p className="max-w-[500px] text-sm lg:text-md">{t('team.description')}</p>

        <div className="flex flex-col items-start space-y-2 lg:items-end lg:space-y-5">
          <p className="text-sm lg:text-right lg:text-md">{t('team.learnMore')}</p>
          <Button className="w-full max-w-[160px] max-lg:p-[10px]" asChild>
            <a href={`/documents/presentations/${i18n.language}.pdf`} target="_blank" rel="noreferrer" download>
              <DownloadIcon className="mr-2 w-3 lg:w-5" />
              PDF
            </a>
          </Button>
        </div>
      </div>

      {level?.level === 0 && <p className="mt-5 w-full text-sm text-pink lg:text-md">{t('team.activate')}</p>}

      <div className="mt-10 flex w-full max-lg:flex-col max-lg:space-y-5 lg:mt-[60px] lg:items-end lg:justify-between lg:space-x-10">
        <div className="flex flex-col space-y-4 lg:space-y-5">
          <p className="font-medium lg:text-lg">{t('team.invite')}</p>
          <a
            className="text-sm text-pink hover:underline lg:text-md"
            href={`${window.location.origin}?ref=${user?.telegram_id}`}
          >
            {window.location.origin}?ref={user?.telegram_id}
          </a>
        </div>

        <ClipboardCopy
          className="w-full max-w-[160px] max-lg:p-[10px]"
          value={`${window.location.origin}?ref=${user?.telegram_id}`}
        >
          {t('team.copy')}
        </ClipboardCopy>
      </div>

      <div className="mt-10 flex w-full max-lg:flex-col max-lg:space-y-5 lg:mt-[60px] lg:justify-between lg:space-x-10">
        <div className="flex flex-col space-y-5 lg:space-y-10">
          <p className="font-medium lg:text-lg">{t('team.teamTitle')}</p>

          {isDefined(referrals) && (
            <div className="flex flex-col space-y-2">
              {referrals.count > 0 &&
                referrals.results.map((referral) => <ReferralItem key={referral.id} referral={referral} />)}

              {referrals.count === 0 && <span>{t('team.notFound')}</span>}
            </div>
          )}

          {isNil(referrals) && <Spinner className="m-auto" />}
        </div>

        <div className="flex w-full max-w-[580px] flex-col space-y-5 lg:space-y-10">
          <h2 className="text-lg font-medium">{t('team.teamStats')}</h2>

          {isNil(depthsCount) && <Spinner className="m-auto" />}
          {isDefined(depthsCount) && (
            <div className="grid grid-cols-2 gap-2 lg:w-full lg:grid-cols-5 lg:gap-5">
              {Array(10)
                .fill(null)
                .map((_, index) => (
                  <Button className="w-full max-w-[160px] max-lg:p-[10px]" key={index}>
                    {depthsCount[`depth_${index + 1}` as keyof DepthsCountResponse]}
                  </Button>
                ))}
            </div>
          )}

          <div className="flex flex-col space-y-2">
            <h2 className="text-lg font-medium">{t('team.lineIncome')}</h2>
            {isDefined(tokenBonuses) && (
              <div className="flex w-full items-end justify-between">
                <span className="text-sm text-white/40 lg:text-md">{t('team.total')}</span>
                <span className="font-medium text-pink lg:text-lg">{tokenBonuses.total_amount}$</span>
              </div>
            )}
          </div>

          {isNil(tokenBonuses) && <Spinner className="m-auto" />}
          {isDefined(tokenBonuses) && (
            <div className="grid grid-cols-2 gap-2 lg:w-full lg:grid-cols-5 lg:gap-5">
              {Array(10)
                .fill(null)
                .map((_, index) => (
                  <Button className="w-full max-w-[160px] max-lg:p-[10px]" key={index}>
                    {tokenBonuses[`depth_${index + 1}` as keyof TokenBonusesResponse]}
                  </Button>
                ))}
            </div>
          )}
        </div>
      </div>

      <div className="mt-10 hidden flex-col items-center space-y-2 text-center lg:space-y-5">
        <p className="text-sm lg:text-md">{t('team.earned')}</p>
        <p className="font-medium text-pink lg:text-lg">{staking?.period_profit ?? '0.00'} CB</p>
      </div>

      <Button
        className="mt-5 hidden w-full max-w-[160px] max-lg:p-[10px] lg:mt-10"
        disabled={isNil(staking) || parseFloat(staking.period_profit) === 0}
      >
        {t('team.claim')}
      </Button>
    </div>
  );
});
