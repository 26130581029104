import { ReactComponent as Level0Image } from './_level-0.svg';
import { ReactComponent as Level1Image } from './_level-1.svg';
import { ReactComponent as Level10Image } from './_level-10.svg';
import { ReactComponent as Level2Image } from './_level-2.svg';
import { ReactComponent as Level3Image } from './_level-3.svg';
import { ReactComponent as Level4Image } from './_level-4.svg';
import { ReactComponent as Level5Image } from './_level-5.svg';
import { ReactComponent as Level6Image } from './_level-6.svg';
import { ReactComponent as Level7Image } from './_level-7.svg';
import { ReactComponent as Level8Image } from './_level-8.svg';
import { ReactComponent as Level9Image } from './_level-9.svg';

export const LevelsMap = {
  0: Level0Image,
  1: Level1Image,
  2: Level2Image,
  3: Level3Image,
  4: Level4Image,
  5: Level5Image,
  6: Level6Image,
  7: Level7Image,
  8: Level8Image,
  9: Level9Image,
  10: Level10Image,
};

export {
  Level0Image,
  Level1Image,
  Level10Image,
  Level2Image,
  Level3Image,
  Level4Image,
  Level5Image,
  Level6Image,
  Level7Image,
  Level8Image,
  Level9Image,
};
