import React, { useCallback, useEffect, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { type AxiosError } from 'axios';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import * as zod from 'zod';

import { UsersService } from '~api/users';
import { Footer, Header } from '~components/layout';
import { BuyTab, StakingTab, TeamTab } from '~components/tabs';
import {
  Button,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from '~components/ui';
import { TELEGRAM_BOT_URL } from '~constants/env';
import { useToast } from '~hooks/use-toast';
import { useStore } from '~store/index';
import { isDefined } from '~utilities/types';

interface AuthFormValues {
  token: string;
}

export const ProfilePage: React.FC = () => {
  const { t } = useTranslation();

  const {
    userStore: { getUser },
    stakingStore: { getStaking },
  } = useStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState<'buy' | 'staking' | 'team'>('buy');
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isRedirectedToBot, setIsRedirectedToBot] = useState(false);
  const { toast } = useToast();

  const authSchema: zod.ZodType<AuthFormValues> = zod.object({
    token: zod.string().min(1, t('header.withdraw.required')),
  });

  const authForm = useForm<AuthFormValues>({
    defaultValues: {
      token: '',
    },
    resolver: zodResolver(authSchema),
  });

  const handleTabSelect = useCallback((newValue: string) => {
    setSelectedTab(newValue as 'buy' | 'staking' | 'team');
  }, []);

  const handleAuthSubmit = useCallback(
    async (values: AuthFormValues) => {
      try {
        await UsersService.getSelf(values.token);
        localStorage.setItem('token', values.token);
        window.location.reload();
      } catch (error) {
        console.error(error);
        toast({
          variant: 'destructive',
          title: t('toaster.error'),
          description:
            ((error as AxiosError)?.response?.data as { detail: string })?.detail ?? t('toaster.unknownError'),
        });
      }
    },
    [toast, t]
  );

  useEffect(() => {
    const token = searchParams.get('token') ?? localStorage.getItem('token');
    if (isDefined(token)) {
      localStorage.setItem('token', token);
      setSearchParams({});
      void getUser(token);
      void getStaking(token);
    } else {
      localStorage.removeItem('token');
      setIsAuthModalOpen(true);
    }
  }, [searchParams, setSearchParams, getUser, getStaking]);

  return (
    <>
      <div className="flex flex-1 flex-col bg-main-layout bg-cover bg-left">
        <Header />
        <div className="flex flex-col px-4 py-6 lg:px-[100px] xl:px-[200px]">
          <Tabs value={selectedTab} onValueChange={handleTabSelect}>
            <TabsList className="max-w-[900px] gap-[10px]">
              <TabsTrigger className="left-clipped-nav-tab group flex bg-pink p-px uppercase lg:p-px " value="buy">
                <div className="flex size-full items-center justify-center bg-dark-gray px-4 py-2 text-pink transition-all group-data-[state=active]:bg-transparent group-data-[state=active]:text-white lg:p-6">
                  {t('tabs.buy')}
                </div>
              </TabsTrigger>
              <TabsTrigger className="group flex bg-pink p-px uppercase lg:p-px " value="staking">
                <div className="flex size-full items-center justify-center bg-dark-gray px-4 py-2 text-pink transition-all group-data-[state=active]:bg-transparent group-data-[state=active]:text-white lg:p-6">
                  {t('tabs.staking')}
                </div>
              </TabsTrigger>
              <TabsTrigger className="right-clipped-nav-tab group flex bg-pink p-px uppercase lg:p-px" value="team">
                <div className="flex size-full items-center justify-center bg-dark-gray px-4 py-2 text-pink transition-all group-data-[state=active]:bg-transparent group-data-[state=active]:text-white lg:p-6">
                  {t('tabs.team')}
                </div>
              </TabsTrigger>
            </TabsList>
            <TabsContent value="buy">
              <BuyTab />
            </TabsContent>
            <TabsContent value="staking">
              <StakingTab />
            </TabsContent>
            <TabsContent value="team">
              <TeamTab />
            </TabsContent>
          </Tabs>
        </div>
        <Footer />
      </div>

      <Dialog open={isAuthModalOpen}>
        <DialogContent showClose={false}>
          <DialogHeader>
            <DialogTitle>{t('auth.title')}</DialogTitle>
            <DialogDescription>{t('auth.description')}</DialogDescription>
          </DialogHeader>

          {isRedirectedToBot ? (
            <Form {...authForm}>
              <form
                onSubmit={authForm.handleSubmit(handleAuthSubmit)}
                className="mt-5 flex flex-col space-y-5 lg:space-y-10"
              >
                <FormField
                  control={authForm.control}
                  name="token"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t('auth.token')}</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button className="mt-5 w-full" type="submit">
                  {t('auth.submit')}
                </Button>
              </form>
            </Form>
          ) : (
            <Button
              onClick={() => {
                setIsRedirectedToBot(true);
              }}
              asChild
            >
              <a
                href={`${TELEGRAM_BOT_URL}?start=${searchParams.get('ref') ?? 0}_auth`}
                target="_blank"
                rel="noreferrer"
              >
                {t('auth.redirect')}
              </a>
            </Button>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};
