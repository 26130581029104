import i18n, { type LanguageDetectorModule } from 'i18next';
import { initReactI18next } from 'react-i18next';

import { cacheSelectedLanguage, getCachedSelectedLanguage } from '~utilities/locale';

import arTranslation from './locales/ar.json';
import enTranslation from './locales/en.json';
import esTranslation from './locales/es.json';
import frTranslation from './locales/fr.json';
import ruTranslation from './locales/ru.json';
import zhTranslation from './locales/zh.json';

const resources = {
  ru: {
    translation: ruTranslation,
  },
  en: {
    translation: enTranslation,
  },
  es: {
    translation: esTranslation,
  },
  fr: {
    translation: frTranslation,
  },
  zh: {
    translation: zhTranslation,
  },
  ar: {
    translation: arTranslation,
  },
};

const LanguageDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  init: () => {
    return getCachedSelectedLanguage() ?? 'en';
  },
  detect: () => {
    return getCachedSelectedLanguage() ?? 'en';
  },
  cacheUserLanguage: (lng) => {
    cacheSelectedLanguage(lng);
  },
};

void i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    lng: getCachedSelectedLanguage() ?? 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export { i18n };
