import React, { useCallback, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { type AxiosError } from 'axios';
import { isNil } from 'lodash';
import { observer } from 'mobx-react';
import Gravatar from 'react-gravatar';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as zod from 'zod';

import {
  Avatar,
  AvatarFallback,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
  Spinner,
} from '~components/ui';
import { useToast } from '~hooks/use-toast';
import { useStore } from '~store/index';
import { isDefined } from '~utilities/types';

import { type StakingFormValues } from './staking-tab.types';

export const StakingTab: React.FC = observer(() => {
  const { t } = useTranslation();
  const {
    stakingStore: { createStaking, updateStaking, staking, loaded, deleteStaking },
    userStore: { user, getUser },
  } = useStore();

  const { toast } = useToast();

  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const [isStakingSubmitLoading, setIsStakingSubmitLoading] = useState(false);

  const stakingSchema: zod.ZodType<StakingFormValues> = zod.object({
    amount: zod.coerce.number().gt(0, t('staking.minAmount')),
  });

  const stakingForm = useForm<StakingFormValues>({
    defaultValues: {
      amount: '',
    },
    resolver: zodResolver(stakingSchema),
  });

  const handleStakingSubmit = useCallback(
    async (values: StakingFormValues) => {
      try {
        setIsStakingSubmitLoading(true);
        isDefined(staking)
          ? await updateStaking(staking.id, { ...values, amount: parseFloat(values.amount as string) })
          : await createStaking({ ...values, amount: parseFloat(values.amount as string) });
        await getUser();
        toast({
          title: t('toaster.success'),
          description: isDefined(staking) ? t('toaster.stakingUpdated') : t('toaster.stakingCreated'),
        });
      } catch (error) {
        toast({
          variant: 'destructive',
          title: t('toaster.error'),
          description:
            ((error as AxiosError)?.response?.data as { detail: string })?.detail ?? t('toaster.unknownError'),
        });
      } finally {
        setIsStakingSubmitLoading(false);
      }
    },
    [toast, staking, updateStaking, createStaking, getUser, t]
  );

  const handleClaim = useCallback(async () => {
    if (isNil(staking)) return;
    try {
      setIsClaimLoading(true);
      await deleteStaking(staking.id);
      await getUser();
      toast({ title: t('toaster.success'), description: t('toaster.rewardClaimed') });
    } catch (error) {
      toast({
        variant: 'destructive',
        title: t('toaster.error'),
        description: ((error as AxiosError)?.response?.data as { detail: string })?.detail ?? t('toaster.unknownError'),
      });
      console.error(error);
    } finally {
      setIsClaimLoading(false);
    }
  }, [getUser, toast, t, staking, deleteStaking]);

  return (
    <div className="max-w-[900px] bg-dark-gray p-[30px] lg:p-[50px]">
      <div className="flex w-full flex-col items-center space-y-10 lg:space-y-[60px]">
        <div className="flex flex-col items-center space-y-5">
          <Avatar className="size-[80px] lg:size-[140px]">
            <AvatarFallback>
              <Gravatar email={user?.telegram_id.toString() ?? ''} size={140} />
            </AvatarFallback>
          </Avatar>

          <span className="break-words text-pink lg:text-md">@{user?.username}</span>
        </div>

        <h1 className="font-medium uppercase lg:text-lg">{t('staking.title')}</h1>

        {isDefined(staking) && (
          <div className="flex w-full flex-col items-center space-y-5">
            <div className="flex w-full items-center max-lg:flex-col max-lg:space-y-5 lg:grid lg:grid-cols-3">
              <div className="flex flex-col items-center space-y-2 lg:space-y-5">
                <span className="text-sm lg:text-md">{t('staking.rewards')}</span>
                <span className="font-medium text-pink lg:text-lg">{staking.period_profit} CB</span>
              </div>

              <div className="flex flex-col items-center space-y-2 lg:space-y-5">
                <span className="text-sm lg:text-md">{t('staking.balance')}</span>
                <span className="font-medium text-pink lg:text-lg">{user?.token_balance ?? '0.00'} CB</span>
              </div>

              <div className="flex flex-col items-center space-y-2 lg:space-y-5">
                <span className="text-sm lg:text-md">{t('staking.locked')}</span>
                <span className="font-medium text-pink lg:text-lg">{staking.amount} CB</span>
              </div>
            </div>

            <Button
              className="mt-5 w-full max-w-[200px] max-lg:p-[10px] lg:mt-10"
              onClick={handleClaim}
              disabled={true /* isNil(staking) || parseFloat(staking.period_profit) === 0 */}
              isLoading={isClaimLoading}
            >
              {t('team.claim')}
            </Button>
          </div>
        )}

        {!loaded.staking && <Spinner />}
        {loaded.staking && isNil(staking) && <p>{t('staking.notFound')}</p>}
      </div>

      <Form {...stakingForm}>
        <form
          onSubmit={stakingForm.handleSubmit(handleStakingSubmit)}
          className="mt-5 flex flex-col space-y-5 lg:mt-[60px] lg:space-y-10"
        >
          <FormField
            control={stakingForm.control}
            name="amount"
            render={({ field }) => (
              <FormItem>
                {loaded.staking && isNil(staking) && (
                  <p className="text-sm uppercase text-white/40 lg:text-md">
                    {t('header.tokenBalance')}{' '}
                    <span className="font-semibold text-pink">{user?.token_balance ?? '...'} CB</span>
                  </p>
                )}
                <FormControl>
                  <Input placeholder={t('staking.placeholder')} {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button className="mt-5 w-full" type="submit" isLoading={isStakingSubmitLoading} disabled={!loaded.staking}>
            {t('staking.submit')}
          </Button>
        </form>
      </Form>
    </div>
  );
});
