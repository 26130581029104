import React, { useCallback, useEffect, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { type AxiosError } from 'axios';
import { observer } from 'mobx-react';
import Gravatar from 'react-gravatar';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { useSearchParams } from 'react-router-dom';
import * as zod from 'zod';

import { type TokenInfoResponse, UsersService, type TokenBonusesResponse } from '~api/users';
import {
  Avatar,
  AvatarFallback,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
} from '~components/ui';
import { useToast } from '~hooks/use-toast';
import { useStore } from '~store/index';
import { isDefined } from '~utilities/types';

import { type BuyFormValues } from './buy-tab.types';

export const BuyTab: React.FC = observer(() => {
  const { t } = useTranslation();
  const { toast } = useToast();
  const {
    userStore: { purchaseTokens, user },
  } = useStore();

  const [searchParams] = useSearchParams();
  const [totalPrice, setTotalPrice] = useState('0');
  const [tokenInfo, setTokenInfo] = useState<TokenInfoResponse | null>(null);
  const [tokenBonuses, setTokenBonuses] = useState<TokenBonusesResponse | null>(null);
  const [isBuyLoading, setIsBuyLoading] = useState(false);

  const buySchema: zod.ZodType<BuyFormValues> = zod.object({
    amount: zod.coerce.number().gt(0, t('buy.minAmount')),
  });

  const buyForm = useForm<BuyFormValues>({
    defaultValues: {
      amount: '',
    },
    resolver: zodResolver(buySchema),
  });

  const getTokenPrice = useCallback(async (token?: string) => {
    try {
      const tokenInfoResponse = await UsersService.getTokenInfo(token);
      setTokenInfo(tokenInfoResponse);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getTokenBonuses = useCallback(async (token?: string) => {
    try {
      const tokenBonusesResponse = await UsersService.getTokenBonuses(token);
      setTokenBonuses(tokenBonusesResponse);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleBuySubmit = useCallback(async () => {
    try {
      setIsBuyLoading(true);
      await purchaseTokens({ amount: parseFloat(totalPrice) });
      toast({ title: t('toaster.success'), description: t('toaster.tokensPurchased') });
      buyForm.reset({ amount: '' });
      setTotalPrice('0');
    } catch (error) {
      toast({
        variant: 'destructive',
        title: t('toaster.error'),
        description: ((error as AxiosError)?.response?.data as { detail: string })?.detail ?? t('toaster.unknownError'),
      });
      console.error(error);
    } finally {
      setIsBuyLoading(false);
    }
  }, [toast, purchaseTokens, buyForm, totalPrice, t]);

  useEffect(() => {
    const token = searchParams.get('token') ?? localStorage.getItem('token');
    if (isDefined(token)) {
      localStorage.setItem('token', token);
      void getTokenPrice(token);
      void getTokenBonuses(token);
    }
  }, [getTokenPrice, getTokenBonuses, searchParams]);

  return (
    <div className="max-w-[900px] bg-dark-gray p-[30px] lg:p-[50px]">
      <div className="flex w-full flex-col justify-between max-lg:space-y-5 lg:flex-row lg:items-end lg:space-x-5">
        <div className="flex w-full items-center justify-between max-lg:w-[360px]">
          <div className="flex flex-col items-start">
            <Avatar className="size-[40px] lg:size-[140px]">
              <AvatarFallback>
                <Gravatar email={user?.telegram_id.toString() ?? ''} size={140} />
              </AvatarFallback>
            </Avatar>

            <span className="mt-2 break-words text-pink lg:mt-4 lg:text-md">@{user?.username}</span>
          </div>

          <div className="flex flex-col space-y-2 text-right text-sm lg:hidden">
            <span className="uppercase text-white/40">{t('buy.balance')}</span>
            <span className="font-medium text-pink">${user?.usd_balance}</span>
            <span className="font-medium text-pink">${user?.token_balance} CB</span>
          </div>
        </div>

        <div className="flex w-full max-w-[360px] flex-col space-y-5 lg:items-end">
          <div className="flex w-full flex-col space-y-1 lg:space-y-3">
            <div className="flex items-center justify-between">
              <span>0 CB</span>
              {isDefined(tokenInfo) && <span>{tokenInfo.total_supply} CB</span>}
            </div>

            <div className="relative h-10 w-full bg-black p-[2px]">
              {isDefined(tokenInfo) && (
                <div
                  style={{
                    width: `${((parseFloat(tokenInfo.purchased) / parseFloat(tokenInfo.total_supply)) * 100).toFixed(4)}%`,
                  }}
                  className="h-full bg-pink-gradient"
                />
              )}

              {isDefined(tokenInfo) && (
                <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
                  {((parseFloat(tokenInfo.purchased) / parseFloat(tokenInfo.total_supply)) * 100).toFixed(4)}%
                </span>
              )}
            </div>

            <div className="flex items-center justify-between">
              <span>{t('buy.collected')}</span>
              {isDefined(tokenInfo) && <span>{tokenInfo.purchased} CB</span>}
            </div>
          </div>

          <div className="flex w-full items-center justify-between">
            <span className="text-sm text-white/40 lg:text-md">{t('buy.refBonus')}</span>

            <span className="font-medium text-pink lg:text-lg">{tokenBonuses?.total_amount ?? '...'}$</span>
          </div>

          <div className="flex w-full items-center justify-between">
            <span className="text-sm text-white/40 lg:text-md">{t('buy.tokenPrice')}</span>

            {isDefined(tokenInfo) && (
              <span className="font-medium text-pink lg:text-lg">
                {t('buy.1tokenPrice', { price: tokenInfo.price })}
              </span>
            )}
          </div>

          <div className="flex w-full items-center justify-between">
            <span className="text-sm text-white/40 lg:text-md">{t('buy.totalAmount')}</span>

            <span className="font-medium text-pink lg:text-lg">{totalPrice} CB</span>
          </div>
        </div>
      </div>

      <Form {...buyForm}>
        <form
          onSubmit={buyForm.handleSubmit(handleBuySubmit)}
          className="mt-10 flex flex-col space-y-5 lg:mt-20 lg:space-y-10"
        >
          <FormField
            control={buyForm.control}
            name="amount"
            render={({ field: { ref: _ref, ...field } }) => (
              <FormItem>
                <FormControl>
                  <NumericFormat
                    {...field}
                    onChange={(event) => {
                      console.log(event.target.value);
                      const parsedAmount = parseFloat(event.target.value);
                      if (!isNaN(parsedAmount) && parsedAmount > 0) {
                        setTotalPrice((parsedAmount / parseFloat(tokenInfo?.price ?? '1')).toFixed(2));
                      } else {
                        setTotalPrice('0');
                      }
                      field.onChange(event);
                    }}
                    placeholder={t('buy.placeholder')}
                    customInput={Input}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button className="mt-5 w-full" type="submit" isLoading={isBuyLoading}>
            {t('buy.submit')}
          </Button>
        </form>
      </Form>
    </div>
  );
});
