import React, { useState } from 'react';

import { isNil } from 'lodash';

import { copyTextToClipboard } from './clipboard-copy.utils';

import { Button, type ButtonProps } from '../button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../tooltip';

type ClipboardCopyProps = {
  value: string;
} & ButtonProps;

const ClipboardCopy = React.forwardRef<HTMLButtonElement, ClipboardCopyProps>(({ value, ...props }, ref) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleClick = async (): Promise<void> => {
    if (isNil(value)) return;

    await copyTextToClipboard(value);
    setTooltipVisible(true);
    setTimeout(() => {
      setTooltipVisible(false);
    }, 2000);
  };

  return (
    <TooltipProvider>
      <Tooltip open={tooltipVisible}>
        <TooltipTrigger asChild>
          <Button ref={ref} {...props} onClick={handleClick} />
        </TooltipTrigger>
        <TooltipContent>
          <p>Copied to clipboard</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
});
ClipboardCopy.displayName = 'ClipboardCopy';

export { ClipboardCopy, type ClipboardCopyProps };
