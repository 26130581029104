export type UserResponse = {
  id: string;
  telegram_id: number;
  username: string;
  first_name: string;
  last_name: string;
  avatar_url: string;
  is_superuser: true;
  is_active: boolean;
  balance: number;
  usd_balance: string;
  token_balance: string;
  speed_level: number;
  wallet_id: string;
  wallet_address_bnb: string;
  wallet_address_bnb_id: string;
  wallet_address_tron: string;
  wallet_address_tron_id: string;
  wallet_address_eth: string;
  wallet_address_eth_id: string;
  twitter_profile: string;
  language_id: number;
};

export type TokenPriceResponse = {
  price: string;
};

export type TokenInfoResponse = {
  price: string;
  total_supply: string;
  purchased: string;
  available: string;
};

export enum Network {
  BNB = 'bnb',
  ETH = 'eth',
  TRX = 'trx',
}

export type WithdrwaRequest = {
  amount: number | string;
  to_address: string;
  network: Network;
  symbol: 'USDT';
};

export type ReferralResponse = {
  id: string;
  username: string;
  telegram_id: number;
};

export type ReferralListResponse = {
  count: number;
  results: ReferralResponse[];
};

export type PurchaseTokensRequest = {
  amount: number;
};

export type LevelResponse = {
  level: number;
  by_me: {
    tokens: string;
    to_usd: string;
  };
  by_structures: {
    tokens: string;
    to_usd: string;
  };
};

export type LevelPermission = {
  by_me: {
    tokens: string;
    to_usd: string;
  };
  by_structures: {
    tokens: string;
    to_usd: string;
  };
};

export type LevelPermissionsResponse = {
  level_1: LevelPermission;
  level_2: LevelPermission;
  level_3: LevelPermission;
  level_4: LevelPermission;
  level_5: LevelPermission;
  level_6: LevelPermission;
  level_7: LevelPermission;
  level_8: LevelPermission;
  level_9: LevelPermission;
  level_10: LevelPermission;
};

export type TokenBonusesResponse = {
  total_amount: string;
  depth_1: string;
  depth_2: string;
  depth_3: string;
  depth_4: string;
  depth_5: string;
  depth_6: string;
  depth_7: string;
  depth_8: string;
  depth_9: string;
  depth_10: string;
};

export type DepthsCountResponse = {
  total_count: number;
  depth_1: number;
  depth_2: number;
  depth_3: number;
  depth_4: number;
  depth_5: number;
  depth_6: number;
  depth_7: number;
  depth_8: number;
  depth_9: number;
  depth_10: number;
};
